import React from "react"
import styles from "./Input.module.css"
import Select from "react-select"
import useWindowDimensions from "../../../hooks/useWindowDimensions "

const Input = ({
    placeholder,
    label,
    mgRight,
    isSearchable,
    options,
    noOptionsMessage,
    onChange,
    inputName,
    value,
}) => {
    const { height, width } = useWindowDimensions()

    return (
        <div
            style={{
                width: "100%",
                marginTop: 12,
                marginRight: mgRight ? mgRight : 0,
            }}
        >
            <label className={styles.label}>{label}</label>
            <Select
                value={value}
                onChange={(choice) => onChange(inputName, choice)}
                noOptionsMessage={() => noOptionsMessage}
                className={styles.input}
                placeholder={placeholder}
                options={options}
                isSearchable={isSearchable}
                styles={{
                    container: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: width < 992 ? 225 : 10,
                        backgroundColor: "#ffbc00",
                        border: 0,
                    }),
                    input: (baseStyles, state) => ({
                        ...baseStyles,
                        border: "none",
                        borderRadius: width < 992 ? 5 : 10,
                        border: 0,
                    }),
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#ffbc00",
                        width: "100%",
                        height: width < 992 ? 36 : 77,
                        borderRadius: width < 992 ? 5 : 10,
                        border: 0,
                        "&:hover": {
                            cursor: "pointer",
                        },
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isSelected ? "black" : null,
                        color: state.isSelected ? "#ffbc00" : null,

                        "&:hover": {
                            backgroundColor: state.isSelected
                                ? null
                                : "#d9d9d9",
                            cursor: "pointer",
                        },
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 0,
                        backgroundColor: "#ffbc00",
                        width: "100%",
                        borderRadius: width < 992 ? 5 : 10,
                        boxShadow: "none",
                        fontSize: width < 992 ? 14 : 25,
                        ":hover": {
                            backgroundColor: "#D9D9D9",
                        },
                    }),
                    menuPortal: (baseStyles, state) => ({
                        ...baseStyles,
                        ":hover": {},
                    }),
                    menuList: (baseStyles, state) => ({
                        ...baseStyles,
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#ffbc00",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        display: "none",
                    }),
                    indicatorsContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        display: "none",
                    }),
                }}
            />
        </div>
    )
}

export default Input
