import React from "react"
import styles from "./Button1.module.css"

const Button1 = ({ children, id, isSelected, onClick }) => {
    let selectedColors
    if (isSelected === id) {
        selectedColors = { backgroundColor: "#000", color: "#ffbc00" }
    }
    return (
        <div
            className={styles.buttonContainer}
            style={selectedColors}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default Button1
