import React from "react"
import styles from "./Announcements.module.css"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import { ReactComponent as ArrowPrev } from "../../assets/icons/arrowLeft2.svg"
import { ReactComponent as ArrowNext } from "../../assets/icons/arrowRight2.svg"

import image16 from "../../assets/images/image12.png"
const Announcements = () => {
    return (
        <div
            style={{
                backgroundColor: "#FFBC00",
                paddingTop: 24,
            }}
            className={styles.sliderContainer}
        >
            <p className={styles.text3}>DUYURULAR</p>
            <Carousel
                className={styles.carouselStyle}
                autoPlay
                infiniteLoop
                interval={10000}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                stopOnHover={false}
                swipeable={false}
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div className={styles.arrowLeft}>
                            <ArrowPrev
                                className={styles.arrow}
                                onClick={clickHandler}
                            />
                        </div>
                    )
                }}
                renderArrowNext={(clickHandler, hasPrev) => {
                    return (
                        <div className={styles.arrowRight}>
                            <ArrowNext
                                className={styles.arrow}
                                onClick={clickHandler}
                            />
                        </div>
                    )
                }}
            >
                <div className={styles.sliderItemDiv}>
                    <img className={styles.image} src={image16} />

                    <div className={styles.textContainer}>
                        <p className={styles.text1}>
                            Tiyatro tarihimiz bu kütüphanede
                        </p>
                        <p className={styles.text2}>
                            Ülkemizin ilk tiyatro ihtisas kütüphanesi Refik
                            Ahmet Sevengil Tiyatro Kütüphanesi eşsiz arşivinin
                            yanı sıra bir oyunla ilgili her türlü bilgiye
                            bilgisayarınızdan erişebileceğiniz Dijital Oyun
                            Bilgi Sistemi ile dikkat çekiyor.
                        </p>

                        <div className={styles.annButton}>DAHA FAZLASI</div>
                    </div>
                </div>
                <div className={styles.sliderItemDiv}>
                    <img className={styles.image} src={image16} />

                    <div className={styles.textContainer}>
                        <p className={styles.text1}>
                            Tiyatro tarihimiz bu kütüphanede
                        </p>
                        <p className={styles.text2}>
                            Ülkemizin ilk tiyatro ihtisas kütüphanesi Refik
                            Ahmet Sevengil Tiyatro Kütüphanesi eşsiz arşivinin
                            yanı sıra bir oyunla ilgili her türlü bilgiye
                            bilgisayarınızdan erişebileceğiniz Dijital Oyun
                            Bilgi Sistemi ile dikkat çekiyor.
                        </p>

                        <div className={styles.annButton}>DAHA FAZLASI</div>
                    </div>
                </div>
                <div className={styles.sliderItemDiv}>
                    <img className={styles.image} src={image16} />

                    <div className={styles.textContainer}>
                        <p className={styles.text1}>
                            Tiyatro tarihimiz bu kütüphanede
                        </p>
                        <p className={styles.text2}>
                            Ülkemizin ilk tiyatro ihtisas kütüphanesi Refik
                            Ahmet Sevengil Tiyatro Kütüphanesi eşsiz arşivinin
                            yanı sıra bir oyunla ilgili her türlü bilgiye
                            bilgisayarınızdan erişebileceğiniz Dijital Oyun
                            Bilgi Sistemi ile dikkat çekiyor.
                        </p>

                        <div className={styles.annButton}>DAHA FAZLASI</div>
                    </div>
                </div>
            </Carousel>
        </div>
    )
}

export default Announcements
