import React, { useState } from "react"
import styles from "./Faq.module.css"
import FaqComp from "./FaqComp"
import { ReactComponent as Question } from "../../assets/icons/ph_question.svg"

const Faq = () => {
    return (
        <div className={styles.faqContainer}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 32,
                }}
            >
                <p className={styles.text1}>SIKÇA SORULAN SORULAR</p>
                <Question className={styles.icon} />
            </div>
            <FaqComp
                question={"Lorem Ipsum?"}
                answer={
                    "Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri standardı sahte metinler olarak kullanılmıştır"
                }
            />

            <FaqComp
                question={"Lorem Ipsum?"}
                answer={
                    "Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri standardı sahte metinler olarak kullanılmıştır"
                }
            />
            <FaqComp
                question={"Lorem Ipsum?"}
                answer={
                    "Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri standardı sahte metinler olarak kullanılmıştır"
                }
            />
            <FaqComp
                question={"Lorem Ipsum?"}
                answer={
                    "Lorem Ipsum, dizgi ve baskı endüstrisinde kullanılan mıgır metinlerdir. Lorem Ipsum, adı bilinmeyen bir matbaacının bir hurufat numune kitabı oluşturmak üzere bir yazı galerisini alarak karıştırdığı 1500'lerden beri endüstri standardı sahte metinler olarak kullanılmıştır"
                }
            />
        </div>
    )
}

export default Faq
