import React, { useEffect, useRef, useState } from "react"
import styles from "./InputScreen.module.css"
import Button1 from "../UI/Buttons/Button1"
import Input from "../UI/Input/Input"
import Button2 from "../UI/Buttons/Button2"
import { ReactComponent as Close } from "../../assets/icons/closeBlack.svg"
import { ReactComponent as Find } from "../../assets/icons/find.svg"
import useWindowDimensions from "../../hooks/useWindowDimensions "
import data from "../../data/data.json"
import { Navigate } from "react-router-dom"
import { useNavigate } from "react-router-dom"
const InputScreen = () => {
    console.log(data)
    const navigate = useNavigate()
    const { height, width } = useWindowDimensions()
    const [isSelected, setIsSelected] = useState("0")

    const [search, setSearch] = useState("")
    const [form, setForm] = useState({
        play: "",
        genre: "",
        language: "",
        season: "",
        job: "",
        person: "",
        district: "",
    })
    const wrapperRef = useRef(null)
    const buttonRef = useRef(null)

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                if (
                    buttonRef.current &&
                    buttonRef.current.contains(event.target)
                ) {
                } else {
                    setIsSelected("0")
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [wrapperRef, buttonRef])
    function updateInputValueHandler(inputType, enteredValue) {
        switch (inputType) {
            case "play":
                setForm((state) => ({
                    ...state,
                    play: enteredValue,
                }))
                break

            case "genre":
                setForm((state) => ({ ...state, genre: enteredValue }))
                break
            case "language":
                setForm((state) => ({
                    ...state,
                    language: enteredValue,
                }))
                break
            case "season":
                setForm((state) => ({
                    ...state,
                    season: enteredValue,
                }))
                break
            case "job":
                setForm((state) => ({
                    ...state,
                    job: enteredValue,
                }))
                break
            case "person":
                setForm((state) => ({
                    ...state,
                    person: enteredValue,
                }))
                break
            case "district":
                setForm((state) => ({
                    ...state,
                    district: enteredValue,
                }))
                break
        }
    }

    const navigateHandler = () => {
        navigate("/sonuclar")
    }

    const clearFormHandler = () => {
        setForm({
            play: "",
            genre: "",
            language: "",
            season: "",
            job: "",
            person: "",
            district: "",
        })
    }

    console.log(form)
    const searchHandler = (inputName, input) => {
        setSearch(input)
    }

    const clearSearchHandler = () => {
        setSearch("")
    }
    console.log(search)
    const setIsSelectedHandler = (selectedId) => {
        // if (isSelected === selectedId) {
        //     setIsSelected("0")
        // } else {
        //     setIsSelected(selectedId)
        // }
        setIsSelected(selectedId)
    }

    console.log(isSelected)
    const mgRight = {
        marginRight: 15,
    }

    const paddingTB = { paddingTop: 78, paddingBottom: 78 }
    return (
        <div className={styles.inputContainer}>
            <div className={styles.buttonContainer} ref={buttonRef}>
                <Button1
                    onClick={
                        isSelected === "1"
                            ? setIsSelectedHandler.bind(this, "0")
                            : setIsSelectedHandler.bind(this, "1")
                    }
                    isSelected={isSelected}
                    id="1"
                >
                    ARAMA
                </Button1>
                <Button1
                    onClick={
                        isSelected === "2"
                            ? setIsSelectedHandler.bind(this, "0")
                            : setIsSelectedHandler.bind(this, "2")
                    }
                    isSelected={isSelected}
                    id="2"
                >
                    GENEL ARAMA
                </Button1>
            </div>
            {isSelected === "1" && (
                <div
                    ref={wrapperRef}
                    className={`${styles.inputInnerContainer} ${styles.fadeInBottom}`}
                >
                    <div className={styles.firstInput}>
                        <Input
                            placeholder={"Oyun Arayınız.."}
                            label={"OYUN ADI"}
                            isSearchable={true}
                            noOptionsMessage={"Sonuç yok"}
                            options={data.plays}
                            onChange={searchHandler}
                            inputName={"search"}
                            value={search}
                        />
                        <div className={styles.buttonContainer2}>
                            <Button2
                                icon={<Find className={styles.icon} />}
                                style={width >= 992 && mgRight}
                                onClick={navigateHandler}
                            >
                                ARA
                            </Button2>

                            <Button2
                                icon={<Close className={styles.icon} />}
                                onClick={clearSearchHandler}
                            >
                                Temizle
                            </Button2>
                        </div>
                    </div>
                </div>
            )}

            {isSelected === "2" && (
                <div
                    ref={wrapperRef}
                    className={`${styles.inputInnerContainer} ${styles.fadeInTop}`}
                    style={width >= 1100 ? paddingTB : {}}
                >
                    <Input
                        placeholder={"Oyun Arayınız.."}
                        isSearchable={true}
                        label={"OYUN ADI"}
                        noOptionsMessage={"Sonuç yok"}
                        options={data.plays}
                        inputName={"play"}
                        onChange={updateInputValueHandler}
                        value={form.play}
                    />
                    <div className={styles.inputDouble}>
                        <Input
                            placeholder={"Kişi Arayınız.."}
                            label={"KİŞİ ADI"}
                            mgRight={5}
                            isSearchable={true}
                            noOptionsMessage={"Sonuç yok"}
                            options={data.persons}
                            inputName={"person"}
                            onChange={updateInputValueHandler}
                            value={form.person}
                        />
                        <Input
                            placeholder={"Görev Seçiniz.."}
                            label={"GÖREV ADI"}
                            isSearchable={false}
                            noOptionsMessage={"Sonuç yok"}
                            options={data.jobs}
                            inputName={"job"}
                            onChange={updateInputValueHandler}
                            value={form.job}
                        />
                    </div>
                    <div className={styles.inputDouble}>
                        <Input
                            placeholder={"Bölge Seçiniz.."}
                            label={"BÖLGE"}
                            mgRight={5}
                            isSearchable={false}
                            noOptionsMessage={"Sonuç yok"}
                            options={data.districts}
                            inputName={"district"}
                            onChange={updateInputValueHandler}
                            value={form.district}
                        />
                        <Input
                            placeholder={"Sezon Seçiniz.."}
                            label={"SEZON"}
                            isSearchable={false}
                            noOptionsMessage={"Sonuç yok"}
                            options={data.seasons}
                            inputName={"season"}
                            onChange={updateInputValueHandler}
                            value={form.season}
                        />
                    </div>
                    <div className={styles.inputDouble}>
                        <Input
                            placeholder={"Dil Seçiniz.."}
                            label={"DİLİ"}
                            mgRight={5}
                            isSearchable={false}
                            noOptionsMessage={"Sonuç yok"}
                            options={data.languages}
                            inputName={"language"}
                            onChange={updateInputValueHandler}
                            value={form.language}
                        />
                        <Input
                            placeholder={"Oyun Türü Seçiniz.."}
                            label={"OYUN TÜRÜ"}
                            isSearchable={false}
                            noOptionsMessage={"Sonuç yok"}
                            options={data.genres}
                            inputName={"genre"}
                            onChange={updateInputValueHandler}
                            value={form.genre}
                        />
                    </div>
                    <div
                        style={{
                            width: "100%",
                            marginTop: 12,
                            display: "flex",
                        }}
                    >
                        <Button2
                            icon={<Find className={styles.icon} />}
                            style={{ width: "100%", marginRight: 5 }}
                            onClick={navigateHandler}
                        >
                            ARA
                        </Button2>
                        <Button2
                            icon={<Close className={styles.icon} />}
                            style={{ width: "100%" }}
                            onClick={clearFormHandler}
                        >
                            Temizle
                        </Button2>
                    </div>
                </div>
            )}
        </div>
    )
}

export default InputScreen
