import React, { useEffect, useRef, useState } from "react"
import styles from "./ResultBox.module.css"
import useHover from "../../hooks/useHover"
import MultiSelect from "../UI/MultiSelect/MultiSelect"

import Modal from "react-modal"
import CustomModal from "../Modal/CustomModal"

const ResultBox = ({ details }) => {
    const ref = useRef(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const onMouseEnter = () => setIsHovered(true)
    const onMouseLeave = () => setIsHovered(false)

    useHover({ ref, onMouseEnter, onMouseLeave })

    Modal.setAppElement(document.getElementById("root"))

    const [modal, setModal] = useState({
        isOpen: false,
        showId: "",
        showName: "",
        modalType: "",
    })

    function openModal(modaltype, showId, showName) {
        setModal((state) => {
            return {
                ...state,
                isOpen: true,
                showId: showId,
                showName: showName,
                modalType: modaltype,
            }
        })
        setIsHovered(false)
    }

    function closeModal() {
        setModal({ isOpen: false, showId: "", showName: "", modalType: "" })
    }

    useEffect(() => {
        if (modal.isOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "unset"
        }
    }, [modal.isOpen])
    return (
        <>
            <CustomModal closeModal={closeModal} modalIsOpen={modal} />
            <div
                ref={ref}
                className={styles.resultBoxContainer}
                // style={{
                //     backgroundImage: `url(${details.image})`,
                // }}
            >
                <img
                    className={styles.image}
                    src={details.image}
                    loading="lazy"
                    onLoad={() => {
                        setIsLoaded(true)
                    }}
                />
                {!modal.isOpen && isLoaded && isHovered && (
                    <div
                        style={{
                            backgroundColor: "rgba(255, 188, 0, 0.7)",
                            borderRadius: 25,
                            width: "100%",
                            height: "100%",
                            position: "relative",
                            zIndex: 33,
                        }}
                        className={styles.slideInRight}
                    >
                        <div
                            style={{
                                paddingTop: 21,
                                paddingLeft: 24,
                                paddingRight: 6,
                            }}
                        >
                            <p className={styles.text1}>{details.date}</p>
                            <p className={styles.text2}>{details.title}</p>
                            <p className={styles.text3}>{details.category}</p>

                            <MultiSelect
                                title={"Kategoriler"}
                                insideButtons={[
                                    {
                                        title: "Galeri",
                                        onClick: () => {
                                            openModal(
                                                "gallery",
                                                1,
                                                details.title
                                            )
                                        },
                                    },
                                    {
                                        title: "Video",
                                        onClick: () => {
                                            openModal("video", 1, details.title)
                                        },
                                    },
                                    {
                                        title: "MP3",
                                        onClick: () => {
                                            openModal("mp3", 1, details.title)
                                        },
                                    },
                                ]}
                            />

                            <MultiSelect
                                title={"Oyuncular"}
                                insideButtons={[
                                    {
                                        title: "Oyuncular",
                                        onClick: () => {
                                            openModal(
                                                "players",
                                                1,
                                                details.title
                                            )
                                        },
                                    },
                                ]}
                            />

                            <MultiSelect
                                title={"Kategoriler"}
                                insideButtons={[
                                    {
                                        title: "Gazete Küpürü",
                                        onClick: () => {
                                            console.log("Gazete Küpürü")
                                        },
                                    },
                                    {
                                        title: "Gazete Küpürü",
                                        onClick: () => {
                                            console.log("Gazete Küpürü")
                                        },
                                    },
                                    {
                                        title: "Gazete Küpürü",
                                        onClick: () => {
                                            console.log("Gazete Küpürü")
                                        },
                                    },
                                ]}
                            />
                            {/* <MultiSelect title={"Oyun Gösterileri"} />
                        <MultiSelect title={"Örnek"} /> */}
                        </div>
                    </div>
                )}
            </div>
            )
        </>
    )
}

export default ResultBox
