import React from "react"
import styles from "./CarouselText.module.css"
import Button1 from "../UI/Buttons/Button1"

export const CarouselText = () => {
    return (
        <div className={styles.textContainer}>
            <div
                style={{
                    display: "flex",
                    alignItems: "end",
                    marginTop: 50,
                    marginBottom: 25,
                }}
            >
                <p className={styles.text1}>perde</p>
                <p className={styles.text2}>Geleceğe</p>
                <p className={styles.text1}>açılıyor</p>
            </div>
            <p className={styles.text3}>
                dilediğiniz yerde, dilediğiniz zaman sınırsız dijital tiyatro
                keyfi her an seyre hazır
            </p>
        </div>
    )
}
