import React from "react"
import styles from "./Footer.module.css"
import logo from "../../assets/images/logo.png"
import { ReactComponent as Mail } from "../../assets/icons/mail.svg"
import { ReactComponent as Tel } from "../../assets/icons/tel.svg"

import { ReactComponent as Insta } from "../../assets/icons/insta.svg"
import { ReactComponent as Face } from "../../assets/icons/facebook.svg"
import { ReactComponent as Twit } from "../../assets/icons/twit.svg"

const Footer = () => {
    return (
        <>
            <div className={styles.footerContainer}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 12,
                    }}
                >
                    <img
                        style={{
                            width: 300,
                        }}
                        src={logo}
                    />
                </div>
                <div className={styles.listContainer}>
                    <p className={styles.text2}>KURUMSAL</p>
                    <div className={styles.text1}>
                        Kişisel verilerin korunuma kanunu
                    </div>
                    <div className={styles.text1}>Gizlilik politikası</div>
                    <div className={styles.text1}>Hakkımızda</div>
                    <div className={styles.text1}>S.S.S</div>
                </div>

                <div className={styles.contact}>
                    <p className={styles.text2}>İLETİŞİM</p>
                    <div className={styles.text3}>
                        <Tel className={styles.icon} />
                        <p>+90 258 555 55 55</p>
                    </div>
                    <div className={styles.text3}>
                        <Mail className={styles.icon} /> <p>örnek@devlet.com</p>
                    </div>
                    <div style={{ marginTop: 12 }}>
                        <Insta className={styles.icon} />
                        <Twit className={styles.icon} />
                        <Face className={styles.icon} />
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: "#FFBC00",
                    minWidth: "100%",
                    height: 58,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                Copyright© 2022 DEVLET TİYATROLARI GENEL MÜDÜRLÜĞÜ | TÜM HAKLARI
                SAKLIDIR
            </div>
        </>
    )
}

export default Footer
