import React from "react"
import styles from "./Button2.module.css"

const Button2 = ({ children, icon, onClick, style }) => {
    return (
        <div
            className={styles.buttonContainer}
            onClick={onClick}
            style={style ? style : {}}
        >
            <label style={{ marginRight: 12 }}>{children}</label>
            {icon}
        </div>
    )
}

export default Button2
