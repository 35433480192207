import React, { useState } from "react"
import styles from "./SoundModalInside.module.css"
import ReactPlayer from "react-player/lazy"
import useWindowDimensions from "../../hooks/useWindowDimensions "
import { ReactComponent as Play } from "../../assets/icons/play.svg"
import { ReactComponent as Pause } from "../../assets/icons/pause.svg"
const SoundModalInside = ({ sounds }) => {
    const { height, width } = useWindowDimensions()
    const [playedIndex, setPlayedIndex] = useState(0)

    const [url, setUrl] = useState(sounds[0].sources[0])
    const [isPlaying, setIsPlaying] = useState(sounds[0].sources[0])

    const nextVideo = () => {
        if (playedIndex === sounds.length - 1) {
            setPlayedIndex((state) => 0)
        } else {
            setPlayedIndex((state) => state + 1)
        }
    }

    const prevVideo = () => {
        if (playedIndex === 0) {
            setPlayedIndex((state) => sounds.length - 1)
        } else {
            setPlayedIndex((state) => state + -1)
        }
    }

    const setUrlHandler = (url, id) => {
        setUrl({
            url: url,
            id: id,
        })
        setIsPlaying(true)
    }

    console.log(isPlaying)

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <div className={styles.soundListContainer}>
                    {sounds.map((item) => {
                        return (
                            <div className={styles.soundListItem}>
                                <p className={styles.text}>{item.title}</p>
                                {url.id === item.id && isPlaying ? (
                                    <Pause
                                        onClick={() => {
                                            setIsPlaying(false)
                                        }}
                                        className={styles.icon}
                                    />
                                ) : (
                                    <Play
                                        onClick={setUrlHandler.bind(
                                            this,
                                            item.sources[0],
                                            item.id
                                        )}
                                        className={styles.icon}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
                <div
                    style={{
                        marginTop: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 12,
                    }}
                >
                    <ReactPlayer
                        config={{
                            file: {
                                attributes: { controlsList: "nodownload" },
                            },
                        }}
                        controls={true}
                        width={300}
                        height={76}
                        onEnded={nextVideo}
                        url={url.url}
                        playing={isPlaying}
                        onPause={() => {
                            setIsPlaying(false)
                        }}
                    />
                    {/* <div
                        style={{
                            marginTop: 16,
                        }}
                    >
                        <ArrowPrev
                            onClick={prevVideo}
                            style={{
                                marginRight: 8,
                            }}
                            className={styles.icon}
                        />
                        <ArrowNext
                            onClick={nextVideo}
                            style={{
                                marginLeft: 8,
                            }}
                            className={styles.icon}
                        />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default SoundModalInside
