import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import image1 from "../../assets/images/CarouselImages/1.png"
import image2 from "../../assets/images/CarouselImages/2.png"
import image3 from "../../assets/images/CarouselImages/3.jpg"
import { ReactComponent as ArrowPrev } from "../../assets/icons/arrowLeft.svg"
import { ReactComponent as ArrowNext } from "../../assets/icons/arrowRight.svg"

import styles from "./CarouselComp.module.css"
import { CarouselText } from "./CarouselText"

const CarouselComp = () => {
    return (
        <div className={styles.sliderContainer}>
            <Carousel
                className={styles.carouselStyle}
                autoPlay
                infiniteLoop
                interval={5000}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                dynamicHeight
                stopOnHover={false}
                renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                        <div className={styles.arrowLeft}>
                            <ArrowPrev
                                className={styles.arrow}
                                onClick={clickHandler}
                            />
                        </div>
                    )
                }}
                renderArrowNext={(clickHandler, hasPrev) => {
                    return (
                        <div className={styles.arrowRight}>
                            <ArrowNext
                                className={styles.arrow}
                                onClick={clickHandler}
                            />
                        </div>
                    )
                }}
            >
                <div className={styles.sliderItemDiv}>
                    <img className={styles.image} src={image1} />
                </div>
                <div className={styles.sliderItemDiv}>
                    <img className={styles.image} src={image2} />
                </div>
                <div className={styles.sliderItemDiv}>
                    <img className={styles.image} src={image1} />
                </div>
                <div className={styles.sliderItemDiv}>
                    <img className={styles.image} src={image3} />
                </div>
            </Carousel>
            <div className={styles.layer}></div>
            <CarouselText />
        </div>
    )
}

export default CarouselComp
