import { useRef, useState, useEffect } from "react"
const useHover = ({ ref, onMouseEnter, onMouseLeave }) => {
    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("mouseenter", onMouseEnter)
            ref.current.addEventListener("mouseleave", onMouseLeave)
        }
        return () => {
            ref.current?.removeEventListener("mouseenter", onMouseEnter)
            ref.current?.removeEventListener("mouseleave", onMouseLeave)
        }
    }, [ref, onMouseEnter, onMouseLeave])

    return
}

export default useHover
