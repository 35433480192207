import React, { useEffect, useRef, useState } from "react"
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrowDownOrange.svg"
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrowUpOrange.svg"

import styles from "./MultiSelect.module.css"

const MultiSelect = ({ title, insideButtons }) => {
    const [isOpen, setIsOpen] = useState(false)

    const setIsOpenHandler = () => {
        setIsOpen((state) => !state)
    }
    const wrapperRef = useRef(null)

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setIsOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [wrapperRef])
    return (
        <>
            <div
                ref={wrapperRef}
                className={styles.selectContainer}
                style={{
                    borderBottomLeftRadius: isOpen ? 0 : 20,
                    borderBottomRightRadius: isOpen ? 0 : 20,
                }}
            >
                <div
                    onClick={setIsOpenHandler}
                    className={styles.selectTopContainer}
                >
                    <p className={styles.btnText}>{title}</p>
                    {isOpen ? (
                        <ArrowUp className={styles.icon} />
                    ) : (
                        <ArrowDown className={styles.icon} />
                    )}
                </div>

                {isOpen && (
                    <div
                        style={{
                            borderBottomLeftRadius: 20,
                            borderBottomRightRadius: 20,
                        }}
                        className={styles.selectBottom}
                    >
                        {insideButtons.map((item) => {
                            return (
                                <div
                                    onClick={item.onClick}
                                    className={styles.selectBottomContainer}
                                >
                                    <p>{item.title}</p>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </>
    )
}

export default MultiSelect
