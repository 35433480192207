import React from "react"
import styles from "./ImageGalleryModalInside.module.css"
import ImageGallery from "react-image-gallery"

import "react-image-gallery/styles/css/image-gallery.css"
import "./gallery.css"

const ImageGalleryModalInside = ({ images }) => {
    return (
        <div className={styles.imageGalleryContainer}>
            <ImageGallery
                useBrowserFullscreen={true}
                showPlayButton={false}
                items={images}
            />
        </div>
    )
}

export default ImageGalleryModalInside
