import React, { useState } from "react"
import styles from "./PlayersTable.module.css"
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
} from "@tanstack/react-table"
import Input from "../UI/Input/Input"
import Button2 from "../UI/Buttons/Button2"
import { ReactComponent as Close } from "../../assets/icons/closeBlack.svg"

const columns = [
    {
        accessorKey: "id",
        header: "Numara",
        cell: (props) => <>{props.getValue()}</>,
    },

    {
        accessorKey: "name",
        header: "İsim",
        cell: (props) => <>{props.getValue()}</>,
    },

    {
        accessorKey: "job",
        header: "İş",
        cell: (props) => <>{props.getValue()}</>,
    },
]
export const PlayersTable = ({ players }) => {
    const [data, setData] = useState(players)
    const [columnFilters, setColumnFilters] = useState([])

    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    })

    console.log(columnFilters)

    const searchName = columnFilters.find((f) => f.id === "name")?.value || ""
    const onFilterChange = (id, value) =>
        setColumnFilters((prev) =>
            prev
                .filter((f) => f.id !== id)
                .concat({
                    id,
                    value,
                })
        )

    const clearFilter = () => {
        setColumnFilters([])
    }
    return (
        <div
            style={{
                width: "100%",
                maxHeight: "95%",
                overflow: "hidden",
                boxSizing: "border-box",
            }}
        >
            <div className={styles.inputContainer}>
                <input
                    placeholder="İsim ara"
                    className={styles.input}
                    value={searchName}
                    onChange={(e) => onFilterChange("name", e.target.value)}
                />
                <div className={styles.clearB} onClick={clearFilter}>
                    Temizle <Close className={styles.icon} />
                </div>
            </div>
            <div className={styles.tableContainer}>
                <table>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr>
                            {headerGroup.headers.map((header) => (
                                <th>{header.column.columnDef.header}</th>
                            ))}
                        </tr>
                    ))}

                    {table.getRowModel().rows.map((row) => (
                        <tr>
                            {row.getVisibleCells().map((cell) => (
                                <td>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
}
