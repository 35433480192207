import React, { useState } from "react"
import styles from "./Navbar.module.css"
import logo from "../../assets/images/logo.png"
import { ReactComponent as Hamburger } from "../../assets/icons/hamburgermenu.svg"
import { ReactComponent as Close } from "../../assets/icons/close.svg"
import { Link, useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
import useWindowDimensions from "../../hooks/useWindowDimensions "
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()

    const { height, width } = useWindowDimensions()

    const hamburgerMenuHandler = () => {
        setIsOpen((state) => !state)
    }
    const hamburgerNav = (
        <div className={styles.hamburger} onClick={hamburgerMenuHandler}>
            {isOpen ? <Close /> : <Hamburger />}
        </div>
    )

    function toggleFullScreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen()
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            }
        }
    }
    const normalNav = (
        <ul className={styles.navList}>
            <li className={styles.listButton}>
                <NavLink
                    to="/"
                    className={({ isActive, isPending }) =>
                        isActive ? styles.active : ""
                    }
                >
                    ANASAYFA
                </NavLink>
            </li>
            <li className={styles.listButton}>
                {/* <NavLink
                    to="/aboutus"
                    className={({ isActive, isPending }) =>
                        isActive ? styles.active : ""
                    }
                >
                    HAKKIMIZDA
                </NavLink> */}
                HAKKIMIZDA
            </li>
            <li className={styles.listButton}>
                {/* <NavLink
                    to="/shows"
                    className={({ isActive, isPending }) =>
                        isActive ? styles.active : ""
                    }
                >
                    GÖSTERİMLER
                </NavLink> */}
                GÖSTERİMLER
            </li>
            <li className={styles.listButton2} onClick={toggleFullScreen}>
                TAM EKRAN
            </li>
        </ul>
    )

    return (
        <>
            <header className={styles.header}>
                <img
                    onClick={() => navigate("/")}
                    src={logo}
                    className={styles.image}
                />
                {/* <Logo height={30} width={300} /> */}
                {width <= 992 ? hamburgerNav : normalNav}
            </header>
            {isOpen && width <= 992 && (
                <div className={styles.hamburgerOpen}>
                    <ul className={styles.hamburgerMenu}>
                        <li className={styles.hamburgerMenuItems}>Anasayfa</li>
                        <li className={styles.hamburgerMenuItems}>
                            Hakkımızda
                        </li>
                        <li className={styles.hamburgerMenuItems}>
                            Gösterimler
                        </li>
                    </ul>
                </div>
            )}
        </>
    )
}

export default Navbar
