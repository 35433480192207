import React from "react"
import InputScreen from "../components/Input/InputScreen"
import Announcements from "../components/Announcements/Announcements"
import Faq from "../components/Faq/Faq"
import CarouselComp from "../components/Carousel/CarouselComp"
const Home = () => {
    return (
        <>
            <CarouselComp />
            <InputScreen />
            <Announcements />
            <Faq />
        </>
    )
}

export default Home
