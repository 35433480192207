import React, { useState } from "react"
import styles from "./VideoModalInside.module.css"
import ReactPlayer from "react-player/lazy"
import useWindowDimensions from "../../hooks/useWindowDimensions "
import { ReactComponent as ArrowPrev } from "../../assets/icons/arrowLeft.svg"
import { ReactComponent as ArrowNext } from "../../assets/icons/arrowRight.svg"
const VideoModalInside = ({ videos }) => {
    const { height, width } = useWindowDimensions()
    const [playedIndex, setPlayedIndex] = useState(0)

    const nextVideo = () => {
        if (playedIndex === videos.length - 1) {
            setPlayedIndex((state) => 0)
        } else {
            setPlayedIndex((state) => state + 1)
        }
    }

    const prevVideo = () => {
        if (playedIndex === 0) {
            setPlayedIndex((state) => videos.length - 1)
        } else {
            setPlayedIndex((state) => state + -1)
        }
    }

    return (
        <>
            <label
                style={{
                    color: "#FFBC00",
                    marginTop: 24,
                    marginBottom: 24,
                }}
                className={styles.text}
            >
                {videos[playedIndex].title}
            </label>
            <div className={styles.video}>
                <ReactPlayer
                    config={{
                        file: { attributes: { controlsList: "nodownload" } },
                    }}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                    style={{}}
                    onEnded={nextVideo}
                    url={videos[playedIndex].sources[0]}
                />
            </div>
            <div
                style={{
                    marginTop: "auto",
                }}
            >
                <ArrowPrev
                    onClick={prevVideo}
                    style={{
                        marginRight: 8,
                    }}
                    className={styles.icon}
                />
                <ArrowNext
                    onClick={nextVideo}
                    style={{
                        marginLeft: 8,
                    }}
                    className={styles.icon}
                />
            </div>
        </>
    )
}

export default VideoModalInside
