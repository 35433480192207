import React, { useState } from "react"
import styles from "./FaqComp.module.css"

import { ReactComponent as ArrowDown } from "../../assets/icons/arrowDown.svg"
import { ReactComponent as ArrowUp } from "../../assets/icons/arrowUp.svg"
import useWindowDimensions from "../../hooks/useWindowDimensions "

const FaqComp = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { height, width } = useWindowDimensions()

    const setIsOpenHandler = () => {
        setIsOpen((prevState) => !prevState)
    }

    console.log(isOpen)
    let heightFaq
    if (isOpen) {
        heightFaq = {
            height: 242,
            paddingBottom: 12,
        }
    }

    return (
        <div className={`${styles.faqCContainer}`} style={heightFaq}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <p className={styles.text1}>{question}</p>
                {isOpen ? (
                    <ArrowUp
                        onClick={setIsOpenHandler}
                        className={styles.icon}
                    />
                ) : (
                    <ArrowDown
                        onClick={setIsOpenHandler}
                        className={styles.icon}
                    />
                )}
            </div>
            {isOpen && (
                <div>
                    <p className={styles.text2}>{answer}</p>
                </div>
            )}
        </div>
    )
}

export default FaqComp
