import React from "react"
import showData from "../../data/showData.json"
import ResultBox from "./ResultBox"
import styles from "./ResultsComp.module.css"
import mask from "../../assets/images/mask.png"

export const ResultsComp = () => {
    return (
        <>
            <div className={styles.topContainer}>
                <div className={styles.textContainer}>
                    <p className={styles.text}>SONUÇLAR</p>
                    <div className={styles.bottomTextCont}>
                        <p className={styles.text2}>Anasayfa {">"}</p>
                        <p className={styles.text3}>&nbsp; Sonuçlar</p>
                    </div>
                </div>
                <img src={mask} className={styles.image} />
            </div>
            <div className={styles.resultsContainer}>
                {showData.map((item) => (
                    <ResultBox details={item} />
                ))}
            </div>
        </>
    )
}
