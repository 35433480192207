import React from "react"
import Modal from "react-modal"
import styles from "./CustomModal.module.css"
import { ReactComponent as Close } from "../../assets/icons/closeCircle.svg"
import useWindowDimensions from "../../hooks/useWindowDimensions "
import VideoModalInside from "./VideoModalInside"
import videos from "../../data/videos.json"
import sounds from "../../data/sounds.json"
import images from "../../data/images.json"
import players from "../../data/players.json"
import SoundModalInside from "./SoundModalInside"
import ImageGalleryModalInside from "./ImageGalleryModalInside"
import { ImageModal } from "./ImageModal"
import { PlayersTable } from "./PlayersTable"

const CustomModal = ({ modalIsOpen, closeModal }) => {
    console.log(modalIsOpen)
    const { height, width } = useWindowDimensions()

    let renderItem

    if (modalIsOpen.modalType === "video") {
        renderItem = <VideoModalInside videos={videos} />
    } else if (modalIsOpen.modalType === "mp3") {
        renderItem = <SoundModalInside sounds={sounds} />
    } else if (modalIsOpen.modalType === "gallery") {
        renderItem = <ImageGalleryModalInside images={images} />
    } else if (modalIsOpen.modalType === "players") {
        renderItem = <PlayersTable players={players} />
    }
    return (
        <div style={{}}>
            {modalIsOpen.modalType === "gallery" ? (
                <ImageModal>
                    <Close
                        style={{
                            top: 34,
                            zIndex: 6669,
                        }}
                        className={styles.icon}
                        onClick={closeModal}
                    />
                    {renderItem}
                </ImageModal>
            ) : (
                <Modal
                    isOpen={modalIsOpen.isOpen}
                    onRequestClose={closeModal}
                    style={{
                        content: {
                            backgroundColor: "#000",
                            borderColor: "#FFBC00",
                            right: width < 768 ? 0 : 40,
                            left: width < 768 ? 0 : 40,
                        },
                        overlay: {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 222,
                            // backgroundColor: "rgba(255,188,0,0.3)",
                        },
                    }}
                    contentLabel="Example Modal"
                >
                    <div className={styles.modalContainer}>
                        <Close className={styles.icon} onClick={closeModal} />
                        <div className={styles.modalInnerContainer}>
                            {renderItem}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default CustomModal
